import React from "react";
import Box from "reusecore/src/elements/Box";
import SectionLayout from "../../components/SectionLayout";
import data from "./data.js";

const SecuritySection = ({ row, col }) => {
  return (
    <SectionLayout
      sectionId="security-policy"
      title="Security Policy"
      description="We value transparency and data protection"
    >
      <Box className="row" {...row} justifyContent="center">
        <Box className="col" {...col} flexDirection="column" flexBox={true}>
          {data.map(function(item, index) {
            return (
              <>
                <h2 style={{ color: "#32325d", fontSize: "28px" }}>
                  {item.title}
                </h2>
                <p style={{ color: "#5d646d", fontSize: "20px", marginTop: 0 }}>
                  {item.content}
                </p>
              </>
            );
          })}
        </Box>
      </Box>
    </SectionLayout>
  );
};

SecuritySection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: "wrap",
  },
  col: {
    width: [1, 2 / 3, 2 / 3, 2 / 3],
  },
};

export default SecuritySection;
