import React from "react";
import SecuritySection from "../containers/SecuritySection";
import MainLayout from "../components/MainLayout";

export default () => {
  return (
    <MainLayout
      title="Security"
      pathname="/security"
      description="We value your privacy so much that we take every possible measure to protect your work: Secure servers, total transparency and strict policy."
    >
      <SecuritySection />
    </MainLayout>
  );
};
