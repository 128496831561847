import React from "react";

const data = [
  {
    title: "Security Policy for VisualEyes",
    content: (
      <>
        At VisualEyes Security is of utmost importance for us. It is a vital
        part and a major concern when choosing our partners and deciding on
        which tools to use. Our users test their confidential designs daily and
        we take every step necessary to protect their work. This page aims to
        provide transparency and to inform anyone interested in respect to
        security related issues. For more information on security matters,
        please contact our live support.
      </>
    )
  },
  {
    title: "Infrastructure",
    content: (
      <>
        All of our services are hosted at{" "}
        <a href="https://cloud.google.com/">Google Cloud Platform (GCP)</a>. GCP
        is a{" "}
        <a href="https://cloud.google.com/security/compliance">
          certified partner
        </a>{" "}
        and enforces multiple layers of transparency, data protection and
        encryption. Each GCP service is certified independently. At VisualEyes,
        we have decided to only use services that are{" "}
        <a href="https://cloud.google.com/security/compliance/soc-2/">SOC 2</a>{" "}
        and{" "}
        <a href="https://cloud.google.com/security/compliance/iso-27001/">
          ISO 27001
        </a>{" "}
        certified to create a secure and encrypted environment.
      </>
    )
  },
  {
    title: "Data encryption in transit and at rest",
    content: (
      <>
        All communications between our service and our servers are encrypted via
        SSL. All internal communications are protected by Google as described{" "}
        <a href="https://cloud.google.com/blog/products/gcp/how-google-protects-your-data-in-transit">
          here
        </a>
        and{" "}
        <a href="https://cloud.google.com/security/encryption-in-transit/">
          here
        </a>
        . All of the persistent storage we use to store user information is{" "}
        <a href="https://cloud.google.com/security/encryption-at-rest/">
          encrypted by Google by default
        </a>
        .
      </>
    )
  },
  {
    title: "Confidentiality",
    content: (
      <>
        Access to data is strictly monitored, restricted and only granted to
        those necessary. Employees are required to sign our confidentiality
        policy agreement which always evolves to reflect the best security
        practices. More information as to how we handle user generated data can
        be viewed in our{" "}
        <a href="https://www.visualeyes.design/policy">Policy</a> and{" "}
        <a href="https://www.loceye.io/terms">Terms & Conditions</a> pages.
      </>
    )
  },
  {
    title: "Data retention",
    content: (
      <>
        VisualEyes stores and processes user provided data in order to provide
        its services. This data can be viewed and can be deleted completely upon
        user request.
      </>
    )
  },
  {
    title: "Events disclosure",
    content: (
      <>
        Server status as well as incident reports can be viewed at our{" "}
        <a href="https://status.visualeyes.design">status page</a>.
      </>
    )
  }
];

export default data;
